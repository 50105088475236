import React, { ReactElement } from "react";

export default function TopCommercialCleanersLogo(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="305"
            height="35"
            fill="none"
            viewBox="0 0 305 35"
        >
            <g clipPath="url(#clip0_4_482)">
                <path
                    fill="#231F20"
                    d="M84.878 26.2h-5.283V14.201c0-2.402-1.638-4.203-3.922-4.203a4.04 4.04 0 00-4.08 4.04V26.2h-5.278V5.801h4.933v1.44c1.283-1.36 3.241-2.12 5.52-2.12a7.839 7.839 0 016.324 2.84c1.436-1.76 3.947-2.84 6.759-2.84 4.879 0 8.238 3.32 8.238 8.159V26.2H92.81V14.32c0-2.56-1.599-4.32-3.922-4.32a3.982 3.982 0 00-4.001 4.079l-.01 12.12zM112.276 5.2c6.32 0 11.346 4.76 11.346 10.803 0 6.043-5.002 10.798-11.346 10.798s-11.277-4.8-11.277-10.798c0-5.999 4.998-10.804 11.277-10.804zm0 16.722a5.917 5.917 0 005.611-3.51 5.914 5.914 0 00-1.151-6.518 5.919 5.919 0 00-10.177 4.109 5.736 5.736 0 005.717 5.92zM123.874 5.801h5.801l5.278 14 5.565-14h5.476l-8.52 20.398h-5.318l-8.282-20.398zM157.794 26.801c-6.64 0-11.519-4.558-11.519-10.72a10.774 10.774 0 013.185-7.752 10.785 10.785 0 017.776-3.13c5.999 0 10.36 4.603 10.36 10.641a20.047 20.047 0 01-.124 2.121h-15.396c.518 2.467 2.56 4.08 5.599 4.08 2.082 0 3.562-.76 5.042-2.52l4.198 3.122c-2.082 2.678-5.318 4.158-9.121 4.158zm4.183-13.038c-.316-2.323-2.156-3.882-4.756-3.882-2.683 0-4.321 1.638-4.962 3.882h9.718z"
                ></path>
                <path
                    fill="#F3B71C"
                    d="M180.505 25.36l-8.198-19.559h5.761l5.358 13.842 5.426-13.842h5.757L182.01 35h-5.663l4.158-9.64zM206.147 5.2c6.32 0 11.317 4.76 11.317 10.803 0 6.043-4.997 10.798-11.317 10.798-6.319 0-11.282-4.8-11.282-10.798 0-5.999 4.998-10.804 11.282-10.804zm0 16.722a5.919 5.919 0 10-5.722-5.92 5.75 5.75 0 001.611 4.17 5.737 5.737 0 004.111 1.75zM238.745 5.801l-.044 12.333c-.04 5.12-3.838 8.643-9.319 8.643-5.402 0-8.958-3.454-8.958-8.643V5.8h5.278v11.48c0 2.802 1.519 4.642 3.803 4.642 2.398 0 3.947-1.88 3.947-4.603V5.8h5.293z"
                ></path>
                <path
                    fill="#231F20"
                    d="M255.424 5.2c6.319 0 11.346 4.76 11.346 10.803 0 6.043-5.003 10.798-11.346 10.798-6.344 0-11.282-4.8-11.282-10.798 0-5.999 5.002-10.804 11.282-10.804zm0 16.722c3.241 0 5.717-2.6 5.717-5.92s-2.467-5.919-5.717-5.919a5.92 5.92 0 100 11.84zM288.021 5.801l-.039 12.333c-.04 5.12-3.838 8.643-9.319 8.643-5.402 0-8.963-3.454-8.963-8.643V5.8h5.283v11.48c0 2.802 1.52 4.642 3.799 4.642 2.397 0 3.946-1.88 3.946-4.603V5.8h5.293zM290.7 5.801h3.68V0h5.278v5.801h4.563V10.4h-4.563v15.786h-5.278V10.399h-3.68V5.8z"
                ></path>
                <path
                    fill="#010101"
                    d="M20.117 26.15a2.728 2.728 0 115.455 0 2.728 2.728 0 01-5.455 0zm33.885-4.232a4.203 4.203 0 00.405-1.796v-3.685c0-.165-.012-.33-.035-.493-.493-9.694-9.185-11.938-9.185-11.938l-.656-.158-.143-.035a2.546 2.546 0 00-.39-.034H42.65A4.81 4.81 0 0038.093.518H8.559a1.48 1.48 0 000 2.96h14.986a1.48 1.48 0 010 2.96H5.97a1.48 1.48 0 000 2.96h12.668a1.48 1.48 0 110 2.96h-7.78a1.48 1.48 0 100 2.96h9.867a1.48 1.48 0 110 2.959H2.61a1.48 1.48 0 000 2.96h12.677a2.377 2.377 0 00-1.095 2.008v.779a2.383 2.383 0 002.383 2.383h1.9v-.262a4.38 4.38 0 118.755 0v.262h13.606v-.262a4.38 4.38 0 118.756 0v.262h2.467a2.383 2.383 0 002.382-2.383v-.78a2.357 2.357 0 00-.404-1.331l-.035.005zm-8.815 6.96a2.728 2.728 0 10.01-5.456 2.728 2.728 0 00-.01 5.456z"
                ></path>
                <path
                    fill="#F3B71C"
                    d="M3.715 3.458a1.48 1.48 0 000-2.96h-2.23a1.48 1.48 0 000 2.96h2.23zM2.6 13.832a1.48 1.48 0 001.48 1.48h2.23a1.48 1.48 0 100-2.96H4.08a1.48 1.48 0 00-1.48 1.48z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_4_482">
                    <path fill="#fff" d="M0 0H304.216V35H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}
