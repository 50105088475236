import React, { ReactElement } from "react";
import MainBanner from "@components/moveyouout/mainBanner/mainBanner";
import MoveYouOutWrapper from "@components/layouts/moveyououtWrapper";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import OffersList from "@components/shared/offersList";

const Benefits = dynamic(() => import("@components/moveyouout/benefits"));

export default function MoveYouOutHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <MoveYouOutWrapper>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <Benefits />
        </MoveYouOutWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
